import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout4';
import SEO from '../components/seo';
// import Citysetting1 from '../components/citysetting/citysetting1';
// import Citysetting2 from '../components/citysetting/citysetting2';
// import Cityset from '../containers/cityset';
// import Datalist from '../components/citysetting/citylist';
import Styles from './fsaas.module.css';

import Image1 from '../images/logo_l2.png';

// const useStateWithLocalStorage = localStorageKey => {
//   const [value, setValue] = React.useState(
//     typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
//     || ''
//   );
 
//   React.useEffect(() => {
//     if (typeof window !== 'undefined') {
//       localStorage.setItem(localStorageKey, value);
//     }
//   }, [value]);
 
//   return [value, setValue];
// };



const App = () => {
  // const [value, setValue] = useStateWithLocalStorage(
  //   'cityset1'
  // );
  // const [value2, setValue2] = useStateWithLocalStorage(
  //   'cityset2'
  // );
 
  // const onChange = event => setValue(event.target.value);
  // const onChange2 = event => setValue2(event.target.value2);
 
  return (
    <Layout>
            <SEO
        title="都市の設定"
        description="都市の設定画面です"
      />


<div id="t"></div>

<div className={Styles.container}>
    <header className={Styles.header}>
    <h1>ジオグラフとは</h1>
    </header>
    <main className={Styles.main}>

    <img src={Image1} alt="image01" style={{maxWidth:"100%"}}/>

{/* 
      <div class="text-input">
          <input
            value={value}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>
        <div class="text-input">
          <input
            value={value2}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange2} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div> */}

    </main>
    <aside className={Styles.sidebar}>
      <p>４.ジオグラフでは他にも、２つの地域を比較したり、国土全体で眺めたりもできる、５種類のモードを備えています。<br/>
      この「モード」は、上段のタブで変えられます。<br/>
      はじめは「自分で地域を選ぶ」モードとなっています。<br/>
      気になるタブを開いてみましょう。<br/>
      なお、「典型例とその解説」モードでは、選んだテーマについての解説も読むことができます。</p>
    </aside>


    <box11 className={Styles.box11}>
      <img src="/gamenimage2.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box11>



    <box12 className={Styles.box12}>
    <p>1. ジオグラフでは、左側に凡例、右側に地域の状況を３次元グラフで「見える化」させた地図が配置されています。<br/>
    <br/>【トップページ】でタイトル部分をクリックし、【テーマ選択ページ】で「見える化」させたいテーマを選び終えると、こちらの【プレビューページ】が表示されます。<br/>
    <br/>
    【プレビューページ】では、まず、見たい地域の市町村名もしくは都道府県名を入力しましょう。３次元グラフの描画が始まります。<br/>
    なお、最初は、データ量が小さくなる面積の小さな市町村で、試しに入力してみると良いでしょう。
    <br/><br/></p>
    </box12>


    <box21 className={Styles.box21}>
<p>
2. ３次元グラフが描画され始めたら、凡例を確認しましょう。こちらの例では、高さは人口、色は昼夜間人口比となっていることがわかります。<br/><br/>
昼夜間人口比とは、昼間と夜の人口の比率のことで、昼/夜で計算します。すなわち大きいほど昼間の人口が多いことを示します。<br/>
<br/>
高い＝人口が多い<br/>
低い＝人口が少ない<br/>
赤い＝昼間の人口が夜より多い<br/>
青い＝昼間の人口が夜より少ない<br/>
<br/>
最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。
</p>
</box21>

    <box22 className={Styles.box22}>
      <img src="/hanreirei2.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box22>

    <box31 className={Styles.box31}>
<p>
３. 地図と３次元グラフを確認しましょう。
<br/>
ジオグラフでは、地図上に人口や産業などのグラフが３次元で可視化されています。
<br/><br/>
以下の操作が可能です。
<br/>
４ケタの数字：数字の年次の３次元グラフを表示します。
はじめに戻る：初期視点に戻します。<br/>
回転させる：中心を固定して回転します。<br/>
場所を確認する：視点の直下の場所をGoogleMapで開きます。<br/>
背景だけを表示：３次元グラフを消します。<br/>
GoogleEarthで表示：描画させた３次元グラフをKMLファイルとしてダウンロードできます。KMLファイルは、GoogleEarthPro上で呼び出すことができます。
<br/><br/>
まずは、「回転させる」を押してグラフを読み取り、「はじめに戻る」を押して元の位置に戻ってみましょう。<br/>
地図は、画面を直接タッチして自由に動かすこともできます。プレビュー画面内の右端のコントロールパネルでも操作できます。
</p>
</box31>

    <box32 className={Styles.box32}>
      <img src="/cesiumgamen3.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box32>








    <footer className={Styles.footer}>
      

    さあ、それではジオグラフを使って地域を探究していきましょう。<br/>
    以下は、代表的なテーマへのリンクとなります。<br/>
    
      {/* <Link to="/101/erabu/#t">1.人口</Link><br/>
      <Link to="/9014/erabu/#t">2.商業</Link><br/>
      <Link to="/22/erabu/#t">3.交通</Link><br/>
      <Link to="/19/erabu/#t">4.都市</Link><br/>
      <Link to="/36/erabu/#t">5.国際化</Link><br/> */}

<br/>
    <table>
	<thead>
		<tr>
			<th>テーマ</th>
			<th>高さ</th>
			<th>色</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>
        <Link to="/11-1/a/#t">人口分布の変化</Link>
      </td>
			<td>人口総数</td>
			<td>人口総数</td>
		</tr>

		<tr>
			<td>
        <Link to="/11-5/a/#t">シャッター通りと郊外型ショッピングセンター</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>小売業年間販売額伸び率（2014年/1985年)</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-3/a/#t">コンパクトシティ</Link>
      </td>
			<td>人口総数</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-2/a/#t">人口の都心回帰</Link>
      </td>
			<td>人口総数</td>
			<td>昼夜間人口比</td>
		</tr>

		<tr>
			<td>
      <Link to="/10-1/a/#t">進む国際化</Link>
      </td>
			<td>外国人人口総数</td>
			<td>人口総数</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-4/a/#t">鉄道・車と商業</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/3-1/a/#t">ニュータウンの高齢化</Link>
      </td>
			<td>６５歳以上人口割合</td>
			<td>６５歳以上人口総数</td>
		</tr>

		<tr>
			<td>
      <Link to="/5-1/a/#t">働く人々の男女比</Link>
      </td>
			<td>全産業従業者数</td>
			<td>全産業従業者数男女比</td>
		</tr>

	</tbody>
	{/* <tfoot>
		<tr>
			<td>フッタセル1</td>
			<td>フッタセル2</td>
			<td>フッタセル2</td>
		</tr>
	</tfoot> */}
</table>



      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>


    </footer>
</div>














    </Layout>
  );
};

export default App;